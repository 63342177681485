<template>
    <div class="min-h-screen bg-white">
        <Header></Header>
        <div class="mx-auto p-4 flex gap-8 mt-[100px]" style="max-width: 1140px">
            <!-- Main Content -->
            <div class="flex-1">
                <!-- Order List Section -->
                <div class="mb-8 bg-white rounded-lg p-6 shadow-lg border-gray-200 border-2 relative">
                    <h2
                        class="flex items-center gap-2 text-lg font-medium mb-4 relative mt-[-40px] bg-white px-4 w-[200px]">
                        1. <span class="text-gray-600">订单项目列表</span>
                        <span class="bg-gray-100 text-xs px-2 py-0.5 rounded">{{ orderItems.length }}</span>
                    </h2>
                    <h4 class="text-sm text-gray-400 mb-6">曲谱({{ orderItems.length }})</h4>
                    <div>
                        <div v-for="(item, index) in orderItems" :key="index"
                            class="mb-4 flex items-center gap-4 hover:bg-gray-50">
                            <img :src="item.avatar" class="w-12 h-12 rounded" />
                            <div class="flex-1">
                                <div class="font-medium">{{ item.title }}</div>
                                <div class="text-gray-500 text-sm">{{ item.composer }}</div>
                            </div>
                            <div class="flex items-center gap-2">
                                <span class="text-gray-600">￥{{ parseFloat(item.price)?.toFixed(2) }}</span>
                                <button class="px-2 py-2 border rounded hover:bg-gray-50" @click="removeItem(item.id)">
                                    <MdDelete size="16" color="#999" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4 flex justify-between items-center mb-10">
                    <span class="font-bold w-1/2">立即注册成为MyMusicSheet的一员，享受成为原创优惠吧！</span>
                    <button
                        class="text-blue-600 flex items-center gap-1 bg-blue-100 px-2 py-1 rounded px-3 py-2 hover:bg-blue-200">
                        <MdDiscount size="16" color="#3975E9" /> 5%折扣 - 赚取 Points
                    </button>
                </div>

                <!-- Buyer Info Section -->
                <div class="mb-8 bg-white rounded-lg p-6 shadow-lg border-gray-200 border-2 relative">
                    <h2
                        class="flex items-center gap-2 text-lg font-medium mb-4 relative mt-[-40px] bg-white px-4 w-[130px]">
                        2. <span class="text-gray-600">买家信息</span>
                    </h2>
                    <div class="flex items-center gap-2 text-sm text-gray-600 mb-4 text-[#0B9D59]">
                        <MdEmail size="16" color="#0B9D59" />
                        请输入用于接收购买通知的电子邮箱地址。
                    </div>
                    <input type="email" placeholder="电子邮箱"
                        class="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        v-model="email" />
                </div>

                <!-- Payment Method Section -->
                <div class="mb-8 bg-white rounded-lg p-6 shadow-lg border-gray-200 border-2 relative">
                    <h2
                        class="flex items-center gap-2 text-lg font-medium mb-4 relative mt-[-40px] bg-white px-4 w-[350px]">
                        3. <span class="">付款方法</span><span
                            class="text-green-600 bg-green-100 text-sm px-2 py-1 rounded">收据支持（学校，公共机构）</span>
                    </h2>
                    <div class="grid grid-cols-3 gap-4">

                        <div
                            class="border-[3px] col-span-1 rounded-xl p-4 flex items-start justify-center cursor-pointer border-green-500 flex-col">
                            <div class="w-[80px] h-[36px] mb-2"><img
                                    src="https://www.mymusicsheet.com/assets/svg/alipaypartner.svg" alt=""></div>
                            <div>Alipay</div>
                        </div>

                    </div>
                </div>

                <!-- Bottom Section -->
                <div class="mt-8 flex items-center gap-4">
                    <img src="https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
                        class="w-12 h-12 rounded-full" />
                    <div>
                        <div class="font-bold text-lg">一起传递感恩</div>
                        <div class="text-sm text-gray-600">
                            成为12104Animenz的支持者！<br />
                            一起为音乐的可持续发展做出贡献。
                        </div>
                    </div>
                    <div class="ml-auto flex items-center gap-2">
                        <span>一次性打赏</span>
                        <div class="w-12 h-6 bg-gray-200 rounded-full relative cursor-pointer">
                            <div
                                class="absolute w-5 h-5 bg-white rounded-full top-0.5 left-0.5 shadow transition-transform">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my-8">
                    <button @click="handlePayment"
                        class="w-full bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600">
                        支付 ¥{{ totalPrice }}
                    </button>
                </div>
            </div>

            <!-- Right Sidebar -->
            <div class="w-80">
                <div class="mb-4">
                    <h3 class="flex items-center gap-2 mb-4"><span>💡</span> 你可能喜欢</h3>
                    <div class="space-y-4">
                        <div v-for="i in 8" :key="i" class="flex items-center gap-3 hover:bg-gray-50 cursor-pointer">
                            <img src="https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
                                class="w-10 h-10 rounded-full" />
                            <div class="flex-1 min-w-0">
                                <div class="font-medium truncate">Avid (86 / Eighty-Six ED)</div>
                                <div class="text-sm text-gray-500">Animenz・钢琴88键・管鸟</div>
                            </div>
                            <div class="text-blue-600 whitespace-nowrap">¥28.72</div>
                            <AiOutlineShopping size="20" color="#3975E9" />
                        </div>
                    </div>
                </div>

                <div class="mt-20">
                    <h3 class="flex items-center gap-2 mb-4">
                        <span>👀</span> 最近浏览记录
                    </h3>
                    <div class="space-y-4">
                        <div v-for="i in 3" :key="i" class="flex items-center gap-3 hover:bg-gray-50 cursor-pointer">
                            <img src="https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
                                class="w-10 h-10 rounded-full" />
                            <div class="flex-1 min-w-0">
                                <div class="font-medium truncate">
                                    Resist (Not Gonna Run Away)
                                </div>
                                <div class="text-sm text-gray-500">
                                    autofxtarz・钢琴88键・管鸟
                                </div>
                            </div>
                            <div class="text-blue-600 whitespace-nowrap">¥21.54</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal for QR Code -->
            <div v-if="isModalVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div class="bg-white p-10 rounded shadow-lg flex flex-col items-center relative">
                    <h3 class="text-lg font-bold mb-4">请扫描二维码进行支付</h3>
                    <img :src="qrcode" alt="">
                    <div class="text-lg font-medium mt-4" v-if="countdown > 0">
                        剩余时间: {{ countdown }} 秒
                    </div>
                    <button @click="cancelPayment" class="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600">
                        取消支付
                    </button>
                    <button @click="closeModal" class="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import Header from "../../components/layout/Header.vue";
import Footer from "../../components/layout/Footer.vue";
import { MdDiscount, MdDelete, MdEmail } from "vue-icons-plus/md";
import { AiOutlineShopping } from "vue-icons-plus/ai";
import { getSheetDetail } from "@/api/sheet"
import { createOrder, payOrder, deleteOrder } from "@/api/order";
import QRCode from 'qrcode';
import { ElMessage } from 'element-plus';

const email = ref("");
const orderItems = ref([]);
const countdown = ref(300); // 5 minutes in seconds
let countdownInterval;

onMounted(async () => {
    const cartIds = JSON.parse(localStorage.getItem('cart')) || []; // 从 localStorage 获取 cart 中的 ID
    const sheetDetails = []; 
    await Promise.all(cartIds.map(async id => {
        const detail = (await getSheetDetail(id)).data; // 获取详细信息
        detail.avatar = `${window.API.DEV_SERVER}/file/user/${detail.artistID}/avatar`
        sheetDetails.push(detail); 
    }));
    orderItems.value = sheetDetails; // 将获取到的数据赋值给 orderItems
});

const totalPrice = computed(() => {
    return orderItems.value.reduce((total, item) => {
        const price = parseFloat(item.price.replace('¥', ''));
        return total + price;
    }, 0).toFixed(2);
});

const removeItem = (id) => {
    orderItems.value = orderItems.value.filter(item => item.id !== id);
    localStorage.setItem('cart', JSON.stringify(orderItems.value.map(item => item.id)));
}

const orderData = ref({});

const handlePayment = async () => {
    if (!email.value) {
        ElMessage.warning('请填写电子邮箱地址');
        return;
    }
    const ids = orderItems.value.map(item => item.id);
    orderData.value = (await createOrder({ sheetIDs: ids, email: email.value })).data;
    const paymentLink = orderData.value.payURL;
    // 生成二维码并显示在弹窗
    const qrCodeCanvas = document.createElement('canvas');
    QRCode.toCanvas(qrCodeCanvas, paymentLink, { errorCorrectionLevel: 'H' }, (error) => {
        if (error) console.error(error);
        // 显示弹窗
        showModalWithQRCode(qrCodeCanvas.toDataURL());
    });
    const interval = setInterval(async () => {
        const paymentLink = (await payOrder(orderData.value.no)).data;
        if (!paymentLink) {
            clearInterval(interval);
            closeModal();
        }
    }, 5000);

    // Start countdown
    countdownInterval = setInterval(() => {
        if (countdown.value > 0) {
            countdown.value--;
        } else {
            clearInterval(countdownInterval);
            closeModal();
        }
    }, 1000);
};

const qrcode = ref('');
const isModalVisible = ref(false);
const showModalWithQRCode = (qrCodeDataUrl) => {
    qrcode.value = qrCodeDataUrl;
    isModalVisible.value = true; // 显示弹窗
};

const closeModal = () => {
    clearInterval(countdownInterval);
    isModalVisible.value = false; // 关闭弹窗
};

const cancelPayment = async () => {
    await deleteOrder(orderData.no);
    closeModal();
};

</script>
