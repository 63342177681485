<template>
    <div class="min-h-screen bg-white">
        <Header></Header>
        <div class="mx-auto p-4 gap-8 mt-[100px]" style="max-width: 1140px">
            <!-- Promotional Carousel -->
            <div class="max-w-7xl mx-auto px-4 py-6">
                <div class="grid grid-cols-3 gap-4">
                    <div v-for="promo in promotions" :key="promo.id" class="relative rounded-lg overflow-hidden h-50">
                        <img :src="promo.image" :alt="promo.title" class=" h-full " />
                    </div>
                </div>
            </div>

            <div class="max-w-7xl mx-auto px-4 py-6">
                <!-- Header -->
                <div class="flex justify-between items-center mb-4">
                    <div class="flex items-center gap-2">
                        <span
                            class="bg-gray-200 text-lg w-8 h-8 flex items-center justify-center rounded-full">🎄</span>
                        <h2 class="text-sm font-medium">排行榜</h2>
                    </div>
                    <button
                        class="text-gray-500 text-sm hover:text-gray-700 border border-gray-200 px-4 py-1 rounded">查看更多
                        ></button>
                </div>

                <!-- Tabs -->
                <div class="flex gap-6 mb-6">
                    <button class="px-4 py-2 text-xs rounded-full bg-gray-600 text-white">圣诞节</button>
                    <button class="px-4 py-2 text-xs text-gray-600 hover:text-gray-600">所有</button>
                    <button class="px-4 py-2 text-xs text-gray-600 hover:text-gray-600">钢琴</button>
                    <button class="px-4 py-2 text-xs text-gray-600 hover:text-gray-600">吉他</button>
                </div>

                <!-- Rankings List -->
                <div class="space-y-4">
                    <div class="grid grid-cols-2 gap-4">
                        <div v-for="(item, index) in rankings" :key="item.id" class="flex items-center gap-4 cursor-pointer" @click="() => $router.push(`/detail/${item.id}`)">
                            <span class="w-6 text-gray-400">{{ index + 1 }}</span>
                            <img :src="item.image" alt="" class="w-12 h-12 rounded-full" />
                            <div class="flex-1">
                                <h3 class="text-sm font-medium">{{ item.title }}</h3>
                                <p class="text-xs text-gray-500">{{ item.artist }}</p>
                            </div>
                            <span class="text-blue-500">{{ item.price }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-6xl mx-auto p-4">
                <!-- Featured Section -->
                <div class="mb-12">

                    <div class="flex justify-between items-center mb-4">
                        <div class="flex items-center gap-2">
                            <span class="bg-gray-200 text-lg w-8 h-8 flex items-center justify-center rounded-full"> ♪
                            </span>
                            <h2 class="text-sm font-medium">策划</h2>
                        </div>
                        <button
                            class="text-gray-500 text-sm hover:text-gray-700 border border-gray-200 px-4 py-1 rounded">查看更多
                            ></button>
                    </div>


                    <div class="grid grid-cols-2 md:grid-cols-6 gap-6">
                        <div v-for="(item, index) in skillLevels" :key="item.id" class="relative group">
                            <img :src="item.image" :alt="item.title"
                                class="w-full aspect-[1/1] object-cover rounded-lg" />
                            <div
                                class="absolute top-[120px] right-3 bg-black/60 text-white text-xs px-2 py-1 rounded-full">
                                曲谱 {{ item.sheetCount }} <!-- Assuming you have a sheetCount property -->
                            </div>
                            <div class="mt-2">
                                <h3 class="text-sm font-medium">{{ item.title }}</h3>
                                <p class="text-xs text-gray-500">{{ item.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Artists of the Month -->
                <div class="max-w-6xl mx-auto p-4">
                    <div class="flex justify-between items-center mb-6">
                        <div class="flex items-center gap-2">
                            <span class="bg-gray-200 text-lg w-8 h-8 flex items-center justify-center rounded-full"> 🏅
                            </span>
                            <h2 class="text-sm font-medium">本月艺术家</h2>
                        </div>
                        <div class="flex items-center gap-4">
                            <span class="text-xs text-gray-500">173 of 1 - 6</span>
                            <div class="flex gap-2">
                                <button
                                    class="w-8 h-8 rounded-full border flex items-center justify-center text-gray-400 hover:bg-gray-50">
                                    <span class="sr-only">Previous</span>
                                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M15 19l-7-7 7-7"></path>
                                    </svg>
                                </button>
                                <button
                                    class="w-8 h-8 rounded-full border flex items-center justify-center text-gray-400 hover:bg-gray-50">
                                    <span class="sr-only">Next</span>
                                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M9 5l7 7-7 7"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-3 md:grid-cols-6 gap-6">
                        <div v-for="artist in artists" :key="artist.id" class="text-center shadow p-4">
                            <img :src="artist.avatar" :alt="artist.name"
                                class="w-full aspect-square rounded-full mb-2" />
                            <h3 class="text-sm font-medium ">{{ artist.name }}</h3>
                        </div>
                    </div>
                </div>

                <div class="max-w-6xl mx-auto p-4">
                    <!-- Header -->
                    <div class="flex justify-between items-center mb-4">
                        <div class="flex items-center gap-2">
                            <span class="bg-gray-200 text-lg w-8 h-8 flex items-center justify-center rounded-full">
                                <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </span>
                            <h2 class="text-sm font-medium">最近浏览记录</h2>
                        </div>
                        <button
                            class="text-gray-500 text-sm hover:text-gray-700 border border-gray-200 px-4 py-1 rounded">查看更多
                            ></button>
                    </div>

                    <!-- List -->
                    <div class="space-y-4">
                        <div v-for="(item, index) in musicSheets" :key="item.id"
                            class="flex items-center justify-between py-2">
                            <div class="flex-1">
                                <h3 class="text-sm font-medium">{{ item.title }}</h3>
                                <div class="flex items-center text-sm text-gray-500">
                                    <span>{{ item.artist }}</span>
                                </div>
                            </div>
                            <div class="flex items-center gap-4 text-xs">
                                <span>{{ item.publisher }}</span>
                                <span class="text-gray-500">{{ item.type }}</span>
                                <span class="text-blue-500">{{ item.price }}</span>
                                <div class="flex gap-2">
                                    <button class="p-1.5 rounded border hover:bg-gray-50">
                                        <svg class="w-4 h-4 text-gray-400" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </button>
                                    <button class="p-1.5 rounded border hover:bg-gray-50">
                                        <svg class="w-4 h-4 text-gray-400" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="max-w-6xl mx-auto p-4">
                <!-- Header -->
                <div class="flex items-center gap-2">
                    <span class="bg-gray-200 text-lg w-8 h-8 flex items-center justify-center rounded-full"> 🔥
                    </span>
                    <h2 class="text-sm font-medium">本月排行</h2>
                </div>

                <div v-for="(artist, index) in month_rank" :key="artist.id" class="mb-8">
                    <div class="flex justify-between items-center mb-4">
                        <div class="flex items-center gap-3">
                            <img :src="artist.avatar" :alt="artist.name" class="w-10 h-10 rounded-full" />
                            <span class="font-medium">{{ artist.name }}</span>
                        </div>
                        <button class="text-gray-500 text-sm hover:text-gray-700">查看更多 ></button>
                    </div>

                    <div class="space-y-4">
                        <div v-for="(song, songIndex) in artist.songs" :key="song.id"
                            class="flex items-center justify-between py-2">
                            <div class="flex items-center gap-4">
                                <img :src="song.image" alt="" class="w-10 h-10 rounded-full" />
                                <div>
                                    <h3 class="text-sm font-medium">{{ song.title }}</h3>
                                    <p class="text-sm text-gray-500">{{ song.details }}</p>
                                </div>
                            </div>
                            <div class="flex items-center gap-4">
                                <span class="text-blue-500">{{ song.price }}</span>
                                <button class="p-1.5 rounded border hover:bg-gray-50">
                                    <svg class="w-4 h-4 text-gray-400" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- 搜索部分 -->
            <section class=" py-12">
                <div class="container mx-auto px-4">
                    <h2 class="text-2xl font-medium mb-6 text-center">
                        按歌曲名、音乐人、歌手或作曲家进行搜索
                    </h2>
                    <div class="flex justify-center relative">
                        <input type="search" placeholder="搜索"
                            class="w-full max-w-2xl px-8 py-2 rounded border bg-gray-100 focus:outline-none text-xs" />
                        <svg class="absolute left-[210px] top-[9px] h-4 w-4 text-gray-400" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                    <div class="flex justify-center mt-4 space-x-4 text-sm">
                        <a href="#"
                            class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                            <MdSearch size="12" color="#777" />热门搜索
                        </a>
                        <a href="#"
                            class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                            <MdSearch size="12" color="#777" />热门搜索
                        </a>
                        <a href="#"
                            class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                            <MdSearch size="12" color="#777" />热门搜索
                        </a>
                        <a href="#"
                            class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                            <MdSearch size="12" color="#777" />热门搜索
                        </a>
                        <a href="#"
                            class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                            <MdSearch size="12" color="#777" />热门搜索
                        </a>

                    </div>
                </div>
            </section>
            <!-- Blog Section -->
            <div class="max-w-7xl mx-auto px-4 py-8">
                <div class="flex items-center justify-between">
                    <h2 class="text-xl font-bold mb-6">MyMusicSheet Blog</h2>
                    <div class="flex items-center gap-4">
                        <span class="text-xs text-gray-500">173 of 1 - 6</span>
                        <div class="flex gap-2">
                            <button
                                class="w-8 h-8 rounded-full border flex items-center justify-center text-gray-400 hover:bg-gray-50">
                                <span class="sr-only">Previous</span>
                                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M15 19l-7-7 7-7"></path>
                                </svg>
                            </button>
                            <button
                                class="w-8 h-8 rounded-full border flex items-center justify-center text-gray-400 hover:bg-gray-50">
                                <span class="sr-only">Next</span>
                                <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M9 5l7 7-7 7"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-4 gap-6">
                    <div v-for="post in blogPosts" :key="post.id" class="space-y-2">
                        <img :src="post.image" :alt="post.title" class="w-full h-48 object-cover rounded-lg" />
                        <div class="text-gray-500 text-sm">{{ post.date }}</div>
                        <h3 class="font-bold line-clamp-2">{{ post.title }}</h3>
                        <p class="text-sm text-gray-600 line-clamp-2">{{ post.excerpt }}</p>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>
    </div>

</template>

<script setup>
import { ref } from 'vue'
import Header from "../../components/layout/Header.vue";
import Footer from "../../components/layout/Footer.vue";
import { MdLocalPrintshop, MdOutlineArrowRightAlt, MdOutlinePiano, MdSearch, MdOutlineSignalCellularAlt, MdMenuBook, MdOutlineFormatQuote, MdOutlineRedeem, MdOutlineLibraryMusic, MdOutlineSpellcheck, MdOutlineEvent } from "vue-icons-plus/md";


const promotions = ref([
    {
        id: 1,
        title: "30% Early Bird Special for Christmas",
        description: "Holiday season special offer",
        image: "https://imgproxy.mapia.io/static/mms/banner/2410_mms_pc_christmas.jpg"
    },
    {
        id: 2,
        title: "Gabriel Piano",
        description: "Innovative arrangements with a unique perspective",
        image: "https://imgproxy.mapia.io/static/mms/banner/2410_mms_pc_side_featured.png"
    },
    {
        id: 3,
        title: "NEWBIE PROMO",
        description: "Special offer for new users",
        image: "https://imgproxy.mapia.io/static/mms/banner/2408_mms_pc_newbie.jpg"
    }
])


const rankings = ref([
    {
        id: 1,
        title: "All I Want for Christmas is You - Mariah Carey",
        artist: "PIANOINU · 钢琴88键",
        price: "¥43.01",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 2,
        title: "Santaclaus is Coming To Town - Carol",
        artist: "Bella & Lucas · 钢琴88键",
        price: "¥35.90",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 3,
        title: "Christmas Eve - Tatsuro Yamashita",
        artist: "CANACANA family · 钢琴88键",
        price: "¥53.85",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 4,
        title: "紫菊花盛开 - 户次菊",
        artist: "MappleZS · 钢琴88键",
        price: "¥21.54",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 5,
        title: "Christmas Song - back number (クリスマスソング)",
        artist: "CANACANA family · 钢琴88键",
        price: "¥53.85",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 6,
        title: "万謡 - 李玉刚",
        artist: "Purrvoice_Hotdog Piano Sheets · 钢琴88键",
        price: "¥21.54",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 7,
        title: "时青的思慕 - HOYO-MIX; 茶理理（原神：枫丹主题曲一）",
        artist: "Toolkit Dango (Lukas XWB) · 钢琴88键",
        price: "¥28.72",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 8,
        title: "Last Christmas - Wham!",
        artist: "PIANOINU · 钢琴88键",
        price: "¥43.01",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 9,
        title: "さよーならまたいつか！ - 未津玄师（C/ Bb/ F/ Eb 演奏谱）",
        artist: "Kel.LStudio | Winds Music · 吉他谱",
        price: "¥21.54",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    },
    {
        id: 10,
        title: "Wham!【Last Christmas】- 毒蛇・麦可（单簧管、高音萨克斯）",
        artist: "那爱弹Jane clarinet! · 单簧管",
        price: "¥36.90",
        image: "https://imgproxy.mapia.io/v3/ZfDJ2QGOIY5bycE3Ri3wzDCVolRRbK2Q/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvYTM5OWE2OGEt/YTI4MS00ZDQ4LTk2/NzgtMzQxOGVhMzNi/NGM5LTE1Nzk3Nzc1/NzguanBn.jpg"
    }
]);
const artists = ref([
    {
        id: 1,
        name: "Animenz",
        avatar: "https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
    },
    {
        id: 2,
        name: "HalcyonMusic",
        avatar: "https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
    },
    {
        id: 3,
        name: "Mr.Li小黄",
        avatar: "https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
    },
    {
        id: 4,
        name: "Ru's Piano Rule",
        avatar: "https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
    },
    {
        id: 5,
        name: "Bella & Lucas",
        avatar: "https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
    },
    {
        id: 6,
        name: "Lazypianist",
        avatar: "https://imgproxy.mapia.io/v3/YFaxk9LvuN_F22rzGE85jD7Yep5xxemv/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9mMmNk/bi5teW11c2ljc2hl/ZXQuY29tL3Byb2Zp/bGUvNGRlMTk1N2E5/NmU3OWZiMDliYjlj/ODk1ZDg4MWIzODYt/MTYxODE0NDA2NS5w/bmc.jpg"
    }
]);

const skillLevels = ref([
    {
        id: 1,
        title: "MMS Pick! : Easy Piano Hits This Season",
        description: "La Campanella和14首曲",
        image: "https://imgproxy.mapia.io/v3/XoZsVOQPrhV9bs0jfiaGyX_1-Bw08fTZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGRhNWM1M2UwNjJm/ODA1NGE4NWNkNTJi/MzZlZWQ1ZDYvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        sheetCount: 15
    },
    {
        id: 2,
        title: "For Intermediate",
        description: "旋转木马和29首曲",
        image: "https://imgproxy.mapia.io/v3/XoZsVOQPrhV9bs0jfiaGyX_1-Bw08fTZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGRhNWM1M2UwNjJm/ODA1NGE4NWNkNTJi/MzZlZWQ1ZDYvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        sheetCount: 30
    },
    {
        id: 3,
        title: "For Expert",
        description: "旋转木马和28首曲",
        image: "https://imgproxy.mapia.io/v3/XoZsVOQPrhV9bs0jfiaGyX_1-Bw08fTZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGRhNWM1M2UwNjJm/ODA1NGE4NWNkNTJi/MzZlZWQ1ZDYvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        sheetCount: 29
    },
    {
        id: 4,
        title: "9TH NEWBIE ARTIST WINNERS!",
        description: "你是我唯一要爱的圣诞礼物和1首曲",
        image: "https://imgproxy.mapia.io/v3/XoZsVOQPrhV9bs0jfiaGyX_1-Bw08fTZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGRhNWM1M2UwNjJm/ODA1NGE4NWNkNTJi/MzZlZWQ1ZDYvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        sheetCount: 2
    },
    {
        id: 5,
        title: "8TH NEWBIE ARTIST WINNERS!",
        description: "Yuusha (THE BRAVE)和2首曲",
        image: "https://imgproxy.mapia.io/v3/XoZsVOQPrhV9bs0jfiaGyX_1-Bw08fTZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGRhNWM1M2UwNjJm/ODA1NGE4NWNkNTJi/MzZlZWQ1ZDYvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        sheetCount: 3
    },
    {
        id: 6,
        title: "7TH NEWBIE ARTIST WINNERS!",
        description: "Nobuo Uematsu - Cleyra Settlement和1首曲",
        image: "https://imgproxy.mapia.io/v3/XoZsVOQPrhV9bs0jfiaGyX_1-Bw08fTZ/g:ce/rs:fill:400:400:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGRhNWM1M2UwNjJm/ODA1NGE4NWNkNTJi/MzZlZWQ1ZDYvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        sheetCount: 2
    }
]);

const musicSheets = ref([
    {
        id: 1,
        title: "Christmas Song (クリスマスソング)",
        artist: "back number",
        publisher: "CANACANA family",
        type: "钢琴88键",
        price: "¥53.85"
    },
    {
        id: 2,
        title: "One Last Kiss (新・福音战士剧场版：终)",
        artist: "宇多田 光",
        publisher: "Animenz",
        type: "钢琴88键",
        price: "¥28.72"
    }
]);


const blogPosts = ref([
    {
        id: 1,
        title: "What About Practicing Earlier So That You Can Project Further?",
        date: "2024.11.16",
        excerpt: "Discover three classic Christmas piano pieces perfect for the holiday season...",
        image: "https://static.wixstatic.com/media/723a6a_ef5282d424a6441d87db1de871a8fdea~mv2.jpg"
    },
    {
        id: 2,
        title: "How to Play Reprise by Joe Hisaishi: A Breakdown of the",
        date: "2024.11.15",
        excerpt: "Learn how to play Joe Hisaishi's Japanese classical piece...",
        image: "https://static.wixstatic.com/media/723a6a_ef5282d424a6441d87db1de871a8fdea~mv2.jpg"
    },
    {
        id: 3,
        title: "MyMusicSheet's 30% Early Bird Special for Christmas",
        date: "2024.11.14",
        excerpt: "Discover MyMusicSheet's 30% Early Bird Christmas Special! Enjoy 30% off...",
        image: "https://static.wixstatic.com/media/723a6a_ef5282d424a6441d87db1de871a8fdea~mv2.jpg"
    },
    {
        id: 4,
        title: "How to Play A Town With An Ocean View: A Look at Studio Ghibli's",
        date: "2024.11.13",
        excerpt: "Download and play Joe Hisaishi's A Town With An Ocean View piano sheet...",
        image: "https://static.wixstatic.com/media/723a6a_ef5282d424a6441d87db1de871a8fdea~mv2.jpg"
    }
])


const month_rank = ref([
    {
        id: 1,
        name: "Hiroyuki Sawano",
        avatar: "https://imgproxy.mapia.io/v3/KSUVYQYhyLSrDjE3uLyaj_wOQNlbKZO6/g:ce/rs:fill:150:150:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGQ5YjNkMzY0ZGMy/YjNhOThiZTNlOTI3/ZDVlMTZkODEvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        songs: [
            {
                id: 1,
                title: 'Call of Silence (From "Attack on Titan Season 2") - Hiroyuki Sawano',
                details: 'Lazypianist · 钢琴88键 · 普通',
                price: '¥43.01',
                image: 'https://imgproxy.mapia.io/v3/KSUVYQYhyLSrDjE3uLyaj_wOQNlbKZO6/g:ce/rs:fill:150:150:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGQ5YjNkMzY0ZGMy/YjNhOThiZTNlOTI3/ZDVlMTZkODEvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg'
            },
            {
                id: 2,
                title: 'Avid (86 / Eighty-Six ED) - Hiroyuki Sawano',
                details: 'Animenz · 钢琴88键 · 普通',
                price: '¥28.72',
                image: 'https://imgproxy.mapia.io/v3/KSUVYQYhyLSrDjE3uLyaj_wOQNlbKZO6/g:ce/rs:fill:150:150:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGQ5YjNkMzY0ZGMy/YjNhOThiZTNlOTI3/ZDVlMTZkODEvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg'
            }
        ]
    },
    {
        id: 2,
        name: "RADWIMPS",
        avatar: "https://imgproxy.mapia.io/v3/KSUVYQYhyLSrDjE3uLyaj_wOQNlbKZO6/g:ce/rs:fill:150:150:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGQ5YjNkMzY0ZGMy/YjNhOThiZTNlOTI3/ZDVlMTZkODEvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg",
        songs: [
            {
                id: 1,
                title: 'Grand Escape (From "Weathering with You") - RADWIMPS',
                details: 'Leon Alex · 木吉他',
                price: '¥34.47',
                image: 'https://imgproxy.mapia.io/v3/KSUVYQYhyLSrDjE3uLyaj_wOQNlbKZO6/g:ce/rs:fill:150:150:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGQ5YjNkMzY0ZGMy/YjNhOThiZTNlOTI3/ZDVlMTZkODEvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg'
            },
            {
                id: 2,
                title: '三葉のテーマ - 君の名は (フィンガースタイル)',
                details: 'Saku サク · 木吉他 · 容易',
                price: '¥35.90',
                image: 'https://imgproxy.mapia.io/v3/KSUVYQYhyLSrDjE3uLyaj_wOQNlbKZO6/g:ce/rs:fill:150:150:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NGQ5YjNkMzY0ZGMy/YjNhOThiZTNlOTI3/ZDVlMTZkODEvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg'
            }
        ]
    },
    // Additional artists can be added here...
]);

</script>