import request from "@/lib/request";


// 获取乐谱列表
export function getSheetList(params) {
  return request({
    url: `/sheet`,
    method: "get",
    params
  });
}

// 获取乐谱数量
export function getSheetCount(params) {
  return request({
    url: `/sheet/count`,
    method: "get",
    params
  });
}

// 获取乐谱列表
export function getSheetCountList(params) {
  return request({
    url: `/sheet/count/list`,
    method: "get",
    params
  });
}

// 获取乐谱详情
export function getSheetDetail(sheetID) {
  return request({
    url: `/sheet/detail/${sheetID}`,
    method: "get"
  });
}

// 删除乐谱
export function deleteSheet(sheetID) {
  return request({
    url: `/sheet/${sheetID}`,
    method: "delete"
  });
}

// 创建新乐谱
export function createSheet(sheetData) {
  return request({
    url: `/sheet`,
    method: "post",
    data: sheetData
  });
}

// 更新乐谱文件
export function updateSheetFile(sheetID, fileName, fileData) {
  return request({
    url: `/sheet/${sheetID}/file/${fileName}`,
    method: "put",
    data: fileData
  });
}
