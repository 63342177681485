import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/tailwindcss.css'
import router from './router'
import ElementPlus from 'element-plus' // 引入element-plus
import 'element-plus/dist/index.css' // 引入element-plus样式
// 图标库的使用说明：https://segmentfault.com/a/1190000045193274?decode__1660=YqGx2DnDuDgCG%3DKDseExCw%2BGUA23qD5dx
const app = createApp(App)
app.use(router)
app.use(ElementPlus) // 使用element-plus
app.mount('#app')
