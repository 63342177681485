<template>
    <div v-if="!reloading" class="min-h-screen bg-white">
        <Header></Header>
        <!-- 主要内容 -->
        <main class=" mt-16  container mx-auto px-4 py-6 grid grid-cols-6 gap-8 " style="max-width: 1140px">
            <!-- 左侧内容 -->
            <div class="col-span-4">
                <div class="bg-white rounded-lg p-6">
                    <h1 class="text-xl font-medium mb-1">
                        {{ sheetData.title }}
                    </h1>
                    <p class="text-gray-500 text-sm mb-4">{{ sheetData.composer }}</p>

                    <!-- 图片加载失败占位符 -->
                    <div class="rounded-lg  text-center ">
                        <div class="swiper-container w-[680px] h-[400px] overflow-hidden relative">
                            <div class="swiper-wrapper grid-cols-6 static">
                                <div class="swiper-slide w-1/2" v-for="(pic, index) in sheetData.blurPics" :key="index">
                                    <img :src="pic" alt="" class="w-full h-full rounded-lg border border-gray-100">
                                </div>
                            </div>
                            <!-- Add Arrows -->
                            <div class="swiper-button-next w-[60px] h-[60px] bg-gray-800/40 rounded-full"
                                style="filter: grayscale(1);opacity: 0.5;transform: scale(0.5);width: 60px !important; height: 60px !important;">
                            </div>
                            <div class="swiper-button-prev w-[60px] h-[60px] bg-gray-800/40 rounded-full"
                                style="filter: grayscale(1);opacity: 0.5;transform: scale(0.5);width: 60px !important; height: 60px !important;">
                            </div>
                        </div>
                    </div>



                    <div class="flex justify-end gap-4 mt-4">
                        <div class="relative mb-6" v-if="sheetData.files?.length > 1">
                            <button @click="isMenuVisible = !isMenuVisible"
                                class="bg-gray-800 text-white text-sm px-4 py-2 rounded">
                                查看目录({{ sheetData.files?.length }})
                            </button>
                            <div v-if="isMenuVisible"
                                class="absolute z-10 bg-white text-black rounded shadow-lg mt-2 w-[300px]">
                                <ul class="py-2">
                                    <li v-for="(file, index) in sheetData.files" :key="index"
                                        class="px-4 py-2 hover:bg-gray-200 cursor-pointer" @click="changeDirectory(index)">
                                        {{ file.name }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                      <!--
                        <button class="bg-gray-800 text-white text-sm px-4 py-2 rounded mb-6">
                            放大查看
                        </button>

                     -->
                    </div>

                    <!-- 操作按钮 -->
                    <div class="grid grid-cols-4 gap-4 mb-8 text-sm border rounded-lg p-4">
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">
                                <MdOutlinePiano color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">乐器</div>
                                <div class="text-xs">{{sheetData.instrumentNames}}</div>
                            </div>
                        </div>
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">
                                <MdOutlineSignalCellularAlt color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">难易度</div>
                                <div class="text-xs"> {{
                                    sheetData.difficulty === 1 ? '初学者' :
                                        sheetData.difficulty === 2 ? '容易' :
                                            sheetData.difficulty === 3 ? '普通' :
                                                sheetData.difficulty === 4 ? '高难度' :
                                                    sheetData.difficulty === 5 ? '专业级' :
                                                        ''
                                }}</div>
                            </div>
                        </div>
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">
                                <MdMenuBook color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">页面</div>
                                <div class="text-xs">{{ sheetData.files ? sheetData.files[0].pageNum : 0 }}</div>
                            </div>
                        </div>
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">
                                <MdOutlineFormatQuote color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">歌词</div>
                                <div class="text-xs">{{sheetData.hasLyrics?'包括':'无'}}</div>
                            </div>
                        </div>
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">

                                <LuMusic4 color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">乐谱类型</div>
                                <div class="text-xs"></div>
                            </div>
                        </div>
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">
                                <MdOutlineLibraryMusic color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">配器法</div>
                                <div class="text-xs"> {{
                                  ['独奏', '二重奏', '三重奏', '四重奏', '五重奏', '多人合奏', '管弦乐团', '乐队', '伴奏'][sheetData.orchestra - 1]
                                  }}</div>
                            </div>
                        </div>
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">
                                <MdOutlineSpellcheck color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">和弦</div>
                                <div class="text-xs">{{sheetData.hasChord?'包括':'无'}}</div>
                            </div>
                        </div>
                        <div class="flex text-gray-500 items-center gap-2">
                            <div class="icon">
                                <MdOutlineEvent color="#999" size="18" />
                            </div>
                            <div class="flex flex-col justify-center ml-2">
                                <div class="text-xs">使用期限</div>
                                <div class="text-xs">永久</div>
                            </div>
                        </div>
                    </div>
                    <!-- bilibili的嵌入视频 -->
                    <div class="video-container" v-if="sheetData.videoSrc">
                        <iframe
                            scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"
                            :src="sheetData.videoSrc"
                            width="100%" height="500"
                            sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"

                            class="w-full mb-8 h-[300px]"></iframe>
                    </div>
                    <div class="text-xs rounded-lg p-2 mb-8 bg-red-100 text-red-500 flex gap-2">
                        <MdOutlineRedeem  size="18" /> 在购买后留下评论即可赚取 ￥3.57 points
                    </div>
                    <!-- 评分部分 -->
                    <div class="pt-6">
                        <h2 class="font-medium mb-4 text-sm">评分 (0)</h2>
                        <div class="flex items-center mb-6">
                            <span class="text-4xl font-medium mr-4">0.0<span
                                    class="text-xs text-gray-400">/5</span></span>
                            <div class="text-gray-300 text-2xl">★★★★★</div>
                        </div>
                    </div>

                    <!-- 评论部分 -->
                    <div class="mt-6 pt-6">
                        <div class="flex justify-between items-center">
                            <h2 class="font-medium mb-4 text-sm">留言 (0)</h2>
                            <label class="flex items-center text-sm text-gray-600 gap-2">
                                仅查看该曲谱购买者的评论
                                <input type="checkbox" class="mr-2" />
                            </label>
                        </div>

                        <div class="flex items-center justify-between mb-4">
                            <img src="https://imgproxy.mapia.io/QFVnbMFTSESd_gr00b64-H7Mq41Ka8L3/fill/200/200/ce/true/aHR0cHM6Ly9tZnMt/cHVibGljLnMzLmFw/LW5vcnRoZWFzdC0y/LmFtYXpvbmF3cy5j/b20vc3RhdGljL25v/LWltZy91c2VyLnBu/Zw.jpg"
                                alt="" class="w-10 h-10 object-cover rounded-full mr-4">
                            <input type="text" placeholder="输入留言..."
                                class="text-sm border rounded-lg px-4 py-2 w-full mr-4 outline-none bg-gray-100" />
                        </div>

                    </div>

                </div>
            </div>

            <!-- 右侧边栏 -->
            <div class="col-span-2 mt-28">
              <div class="bg-white rounded-lg p-6 shadow mb-4">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-4">
                  <img :src="sheetData.avatar" width="50" height="50" alt="author" class="rounded-full">
                  <div class="flex flex-col items-center">
                    <p class="text-sm font-medium">{{ sheetData.author }}</p>
                    <p class="text-xs text-gray-500">曲谱({{authorSheetCount}})</p>
                  </div>

                </div>
                <button class="p-2 hover:bg-gray-50 rounded-full flex flex-col items-center gap-1">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                  </svg>
                  <span class="text-xs">9</span>
                </button>
              </div>
            </div>
                <div class="bg-white rounded-lg p-6 shadow mb-4">
                    <div class="flex justify-between items-center mb-4">
                        <span class="text-2xl font-medium">¥ {{parseFloat(sheetData.price).toFixed(2)}} 元</span>
                        <div class="flex space-x-2">
                            <button class="p-2 hover:bg-gray-50 rounded-full">
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                </svg>
                            </button>
                          <!--
                            <button class="p-2 hover:bg-gray-50 rounded-full">
                                <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            -->
                        </div>
                    </div>
                  <!--
               <div class="text-xs rounded-lg p-2 mb-8  flex gap-2">
                   <MdOutlineRedeem  size="18" /> 在购买后留下评论即可赚取 ￥3.57 points
               </div>
               -->
               <div class=" flex justify-between items-center gap-4">
                   <button class="w-full text-white bg-red-500 hover:bg-red-400 duration-100 py-2 rounded-lg mb-4"
                       @click="handleBuy">
                       立即购买
                   </button>

                 <!--
                   <button
                       @click="handleBuy"
                       class="w-full bg-red-500 text-white py-2 rounded-lg mb-4 flex items-center justify-center">
                       <AiOutlineShopping color="#fff" />加入购物车
                   </button>

                   -->
                    </div>

                    <div class="text-sm text-gray-500 space-y-2">
                        <p>退款保证 - 安全交易</p>
                        <div class="flex items-center">
                            <div class="flex items-center gap-1">
                                <svg height="30" width="30" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h32v32H0z" fill="#fff"></path>
                                        <path
                                            d="M12.71 18.294a.782.782 0 0 1-.36.082.786.786 0 0 1-.694-.407l-.055-.109-2.192-4.666a.337.337 0 0 1-.027-.163.366.366 0 0 1 .11-.27.384.384 0 0 1 .278-.11c.09.003.176.031.25.082l2.58 1.79c.2.12.43.185.665.19.143 0 .284-.028.416-.08l12.096-5.264c-2.193-2.523-5.771-4.15-9.793-4.15C9.354 5.219 4 9.586 4 14.985c0 2.93 1.609 5.588 4.133 7.38a.698.698 0 0 1 .278.868c-.194.732-.527 1.927-.527 1.98a.956.956 0 0 0-.055.299.366.366 0 0 0 .11.27.39.39 0 0 0 .277.108.32.32 0 0 0 .222-.081l2.608-1.493a1.34 1.34 0 0 1 .638-.19c.122.005.243.023.36.055a14.2 14.2 0 0 0 3.912.543c6.602 0 11.983-4.367 11.983-9.766a8.44 8.44 0 0 0-1.359-4.53L12.794 18.24z"
                                            fill="#1aad19" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                                <svg height="22" width="22" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h32v32H0z" fill="#1c9fe5"></path>
                                        <path
                                            d="M23.104 18.98a142.494 142.494 0 0 0 11.052 3.848c2.044.85 0 5.668-2.159 4.674-2.444-1.066-7.359-3.245-11.097-5.108C18.822 24.842 15.556 28 10.907 28 6.775 28 4 25.568 4 21.943c0-3.053 2.11-6.137 6.82-6.137 2.697 0 5.47.766 8.785 1.922a25.007 25.007 0 0 0 1.529-3.838l-11.981-.006v-1.848l6.162.015V9.63H7.808V7.81l7.507.006V5.115c0-.708.38-1.115 1.042-1.115h3.14v3.827l7.442.005v1.805h-7.44v2.431l6.088.016s-.754 3.904-2.483 6.897zM5.691 21.79v-.004c0 1.736 1.351 3.489 4.64 3.489 2.54 0 5.028-1.52 7.408-4.522-3.181-1.592-4.886-2.357-7.348-2.357-2.394 0-4.7 1.164-4.7 3.394z"
                                            fill="#fff" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>



              <!--  打印测试

                <div class="bg-white rounded-lg p-6 shadow mb-4">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-4">
                            <MdLocalPrintshop size="20" color="#444" />
                            <div class="flex flex-col items-center">
                                购买前请进行打印测试！
                            </div>
                        </div>
                        <MdOutlineArrowRightAlt size="20" color="#444" />
                    </div>
                </div>
              -->

            </div>
        </main>




        <div class="bg-gray-50 p-6 mt-6 ">
            <div class="w-[1140px] mx-auto flex justify-between">
                <!-- 相关音乐部分 -->
                <div class="pt-6">
                    <h2 class="text-lg font-medium mb-4">来自 {{sheetData.author}} 的其他乐谱</h2>
                    <div v-for="item in relatedSheets" :key="item.title"  @click.prevent="goSheetDetail(item.id)"
                        class="flex items-center w-[470px] py-4 px-2 border border-gray-100 text-xs gap-6 hover:border-red-400">
                        <div class="w-[260px]">
                            <h3 class="font-medium text-xs">{{ item.title.length > 10 ? item.title.substring(0, 10) +
                                '...' : item.title }}</h3>
                            <p class="text-xs text-gray-600">{{ item.category }}</p>
                        </div>
                        <div class="font-medium text-red-500">￥{{ parseSheetPrice(item.price) }}</div>
                        <button class="px-1 py-1 border rounded hover:bg-gray-50">
                            <AiOutlineShopping size="14" />
                        </button>
                        <button class="px-1 py-1">
                            <BiFile size="14" color="#999" />
                        </button>
                    </div>

                </div>

                <!-- 你可能会喜欢部分 -->
                <div class="pt-6">
                    <h2 class="text-lg font-medium mb-4">你可能会喜欢</h2>
                    <div v-for="item in likeMusic" :key="item.title"
                        class="flex items-center w-[470px] py-4 px-2 border border-gray-100 text-xs gap-6 hover:border-[#2188FF]">
                        <img src="https://imgproxy.mapia.io/v3/nrPSgJ01_eYC6lJUIlJiYyukkB89aL5o/g:ce/rs:fill:200:200:true/aHR0cHM6Ly9tZnMu/cGQubWFwaWEuaW8v/cHVibGljL2ZpbGUv/NDA5Yjg0ZGE1NDNm/OWM0OWJmYjNkMTI2/MjVkMTk2NmQvZG93/bmxvYWQ_U2Vydmlj/ZS1Qcm92aWRlcj1t/bXM.jpg"
                            alt="Music" class="w-8 h-8 object-cover rounded-full" />
                        <div class="w-[240px]">
                            <h3 class="font-medium text-xs">{{ item.title.length > 10 ? item.title.substring(0, 10) +
                                '...' : item.title }}</h3>
                            <p class="text-xs text-gray-600">{{ item.category }}</p>
                        </div>
                        <div class="font-medium text-[#2188FF] w-[60px]">{{ item.price }}</div>
                        <button class="px-1 py-1 border rounded hover:bg-gray-50">
                            <AiOutlineShopping size="14" color="#2188FF" />
                        </button>
                        <button class="px-1 py-1">
                            <BiFile size="14" color="#999" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="w-[1140px] mx-auto mt-10">
                <h2 class="text-lg font-medium mb-4">最近浏览纪录</h2>
                <div class="flex flex-wrap items-center justify-between gap-4">
                    <div v-for="item in recentView" :key="item.title"
                        class="flex items-center w-[470px] py-4 px-2 border border-gray-100 text-xs gap-6 hover:border-[#2188FF]">
                        <div class="w-[260px]">
                            <h3 class="font-medium text-xs">{{ item.title.length > 10 ? item.title.substring(0, 10) +
                                '...' : item.title }}</h3>
                            <p class="text-xs text-gray-600">{{ item.category }}</p>
                        </div>
                        <div class="font-medium text-[#2188FF]">{{ item.price }}</div>
                        <button class="px-1 py-1 border rounded hover:bg-gray-50">
                            <AiOutlineShopping size="14" color="#2188FF" />
                        </button>
                        <button class="px-1 py-1">
                            <BiFile size="14" color="#999" />
                        </button>
                    </div>
                </div>

            </div>

        </div>
        <!-- 搜索部分 -->
        <section class=" py-12">
            <div class="container mx-auto px-4">
                <h2 class="text-2xl font-medium mb-6 text-center">
                    按歌曲名、音乐人、歌手或作曲家进行搜索
                </h2>
                <div class="flex justify-center">
                    <input type="search" placeholder="搜索"
                        class="w-full max-w-2xl px-8 py-2 rounded border bg-gray-100 focus:outline-none text-xs" />
                    <svg class="absolute left-[210px] top-[9px] h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>
                <div class="flex justify-center mt-4 space-x-4 text-sm">
                    <a href="#"
                        class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                        <MdSearch size="12" color="#777" />热门搜索
                    </a>
                    <a href="#"
                        class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                        <MdSearch size="12" color="#777" />热门搜索
                    </a>
                    <a href="#"
                        class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                        <MdSearch size="12" color="#777" />热门搜索
                    </a>
                    <a href="#"
                        class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                        <MdSearch size="12" color="#777" />热门搜索
                    </a>
                    <a href="#"
                        class="text-xs bg-gray-200 px-2 py-1 rounded text-gray-400 hover:bg-gray-300 flex gap-1 items-center">
                        <MdSearch size="12" color="#777" />热门搜索
                    </a>

                </div>
            </div>
        </section>
        <Footer></Footer>
        <!-- 页脚 -->

    </div>
</template>
<script setup>
import { ref } from "vue";
import Header from "../../components/layout/Header.vue";
import Footer from "../../components/layout/Footer.vue";
import { MdLocalPrintshop, MdOutlineArrowRightAlt, MdOutlinePiano, MdSearch, MdOutlineSignalCellularAlt, MdMenuBook, MdOutlineFormatQuote, MdOutlineRedeem, MdOutlineLibraryMusic, MdOutlineSpellcheck, MdOutlineEvent } from "vue-icons-plus/md";
import { LuMusic4 } from "vue-icons-plus/lu";
import { BiFile, BiChevronDown } from "vue-icons-plus/bi"
import { AiOutlineShopping, AiOutlineMail, AiFillSafetyCertificate } from "vue-icons-plus/ai"
import {getSheetCount, getSheetDetail, getSheetList} from "@/api/sheet"
import { useRouter } from "vue-router"
import { onMounted } from "vue";
import Swiper from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { nextTick } from "vue";
import {parseSheetDifficulty, parseSheetPrice} from "@/parser/sheet";
Swiper.use([Autoplay, Navigation]);

const router = useRouter()

const sheetData = ref({
  instruments: []
})
const authorSheetCount = ref(0)
const fetchData = (id) => {

    getSheetDetail(id).then(res => {
        const sheet = res.data
        sheet.avatar = `${window.API.DEV_SERVER}/file/user/${sheet.artistID}/avatar`
        sheet.blurPics = Array.from({ length: sheet.files[0].pageNum }, (_, index) =>
            `${window.API.DEV_SERVER}/file/sheet/${sheet.files[0].sheetID}/blur/${sheet.files[0].name}/${index + 1}`
        );


        sheet.instrumentNames = Array.from(sheet.instruments, data=>data.name).join("/")
        let bv = ''
        if (sheet.videoURL){
          const tokens = sheet.videoURL.split("/")

          for (let idx in tokens){
            if (tokens[idx] === 'video'){
              bv = tokens.length > idx + 1?tokens[idx + 1]:''
              break
            }
          }
          sheet.videoSrc = bv === '' ?'':`//player.bilibili.com/player.html?bvid=${bv}&page=1&high_quality=1&danmaku=0`
        }else{
          sheet.videoSrc = ''
        }

        getSheetCount({artistID:sheet.artistID}).then(res =>{
          authorSheetCount.value = res.data
        })

        sheetData.value = sheet

        console.log(sheet)
        swiperInit()
    })
}

const changeDirectory = (index) => {
    sheetData.value.blurPics = Array.from({ length: sheetData.value.files[index].pageNum }, (_, _index) =>
        `${window.API.DEV_SERVER}/file/sheet/${sheetData.value.files[index].sheetID}/blur/${sheetData.value.files[index].name}/${_index + 1}`
    );
    swiperInit()
}
const swiperInit = () => {
    nextTick(() => {
      new Swiper('.swiper-container', {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 2,
            loop: false,
            loopFillGroupWithBlank: true,
            pagination: {
              el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

        });
    });


}

async function fetchAuthorOthers() {
  const sheets = (await getSheetList({"artistID": sheetData.value.artistID, "limit": 5})).data
  if (sheets){
    sheets.forEach(s => {
      s.category = s.author + "・" + Array.from(s.instruments, i=> i.name).join("/")+ "・" + parseSheetDifficulty(s.difficulty)
    })
    relatedSheets.value = sheets.filter(v => v.id !== sheetData.value.id)
  }

}

const handleBuy = () => {
    const cartIds = JSON.parse(localStorage.getItem('cart')) || [];
    if (!cartIds.includes(router.currentRoute.value.params.id)) {
        cartIds.push(router.currentRoute.value.params.id);
    }
    localStorage.setItem('cart', JSON.stringify(cartIds));
    router.push(`/payment`);
}

onMounted(() => {
    fetchData(router.currentRoute.value.params.id)
    fetchAuthorOthers()
})

const isMenuVisible = ref(false)


const actions = [
    {
        icon: "M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z",
        label: "收藏",
    },
    {
        icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
        label: "难度",
    },
    {
        icon: "M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3",
        label: "试听",
    },
    {
        icon: "M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z",
        label: "打印",
    },
    {
        icon: "M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z",
        label: "分享",
    },
];


// 作者其他乐谱
const relatedSheets = ref([]);

const likeMusic = [
    {
        title: "おめでとうクリスマス (连弹 / 初级 / 圣诞曲)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 21.37",
    },
    {
        title: "荒野の果てに (连弹初级 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 40.70",
    },
    {
        title: "荒野の果てに (独奏 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 21.37",
    },
    {
        title: "荒野の果てに (独奏 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 40.69",
    },
    {
        title: "荒野の果てに (连弹中级 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 21.37",
    },
];

const recentView = [
    {
        title: "おめでとうクリスマス (连弹 / 初级 / 圣诞曲)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 21.37",
    },
    {
        title: "荒野の果てに (连弹初级 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 40.70",
    },
    {
        title: "荒野の果てに (独奏 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 21.37",
    },
    {
        title: "荒野の果てに (独奏 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 40.69",
    },
    {
        title: "荒野の果てに (连弹中级 / 圣诞)",
        category: "Motch Music・管弦乐・单曲",
        price: "¥ 21.37",
    },
];
const reloading = ref(false);
const goSheetDetail = (id) => {
  reloading.value = true;
  router.push({ path: '/detail/' + id }).then(() => {
    fetchData(router.currentRoute.value.params.id)
    fetchAuthorOthers()
    reloading.value = false
  })
}
</script>

<style>
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
</style>
