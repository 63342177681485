<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
*,
body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Noto Sans SC, Noto Sans JP, Noto Sans KR, \534e\6587\7ec6\9ed1, STXihei, PingFang TC, \5fae\8f6f\96c5\9ed1\4f53, Microsoft YaHei New, \5fae\8f6f\96c5\9ed1, Microsoft Yahei, \5b8b\4f53, SimSun, Helvetica Neue, Helvetica, Arial, sans-serif;
}
</style>
