import request from "@/lib/request";

// 获取订单列表
export function getOrderList() {
  return request({
    url: `/order`,
    method: "get"
  });
}

// 创建订单
export function createOrder(data) {
  return request({
    url: `/order/sheet`,
    method: "post",
    data
  });
}

// 获取订单付款链接
export function payOrder(orderNo) {
  return request({
    url: `/order/${orderNo}/pay`,
    method: "get"
  });
}

// 删除订单
export function deleteOrder(orderNo) {
  return request({
    url: `/order/${orderNo}`,
    method: "delete"
  });
}
