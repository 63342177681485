import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home/index.vue";
import Main from "../views/Main/index.vue";
import Detail from "../views/Detail/index.vue";
import Payment from "../views/Payment/index.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {path: "/main", name: "Main", component: Main},
  {
    path: "/detail/:id",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;