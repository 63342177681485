

export function parseSheetDifficulty(difficulty) {
    return difficulty === 1 ? '初学者' :
            difficulty === 2 ? '容易' :
                difficulty === 3 ? '普通' :
                    difficulty === 4 ? '高难度' :
                        difficulty === 5 ? '专业级' :
                            ''
}

export function  parseSheetPrice(price){
    return parseFloat(price)?.toFixed(2)
}